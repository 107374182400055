import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import WebRTC from "../../components/SingleCase/WebRTC"
import ContactBtnBlock from "../../components/SingleCase/ContactBtnBlock"
import Navigate from "../../components/SingleCase/Navigate"
import RelatedProjects from "../../components/SingleCase/RelatedProjects"
import dataForWebRTC from "../../information/Cases/dataForWebRTC"
import { mainUrl } from "../../js/config"
import "../../components/SingleCase/PortfolioComponent.scss"

export default class WebRTCPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
    }
  }

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm })
  }

  render() {
    const {
      classComponent,
      nextLink,
      prevLink,
      relatedProjects,
      data,
    } = dataForWebRTC

    return (
      <section className="singleProject WebRTC">
        <SEO
          title="Remote Assistance Software Development Case — OS-System"
          description="Connect with service technicians and household owners with ease! Our mobile and web apps offer video and audio calls, screen sharing, and user management."
          canonical={`${mainUrl}/cases/webrtc/`}
        />
        <Layout showFormPoint={200}>
          <WebRTC data={data} />
          <Navigate
            classComponent={classComponent}
            nextLink={nextLink}
            prevLink={prevLink}
          />
          <ContactBtnBlock />
          <RelatedProjects relatedProjects={relatedProjects} />
        </Layout>
      </section>
    )
  }
}
