import {
  Section1Bg,
  Section3Bg,
  Section3Mockup,
  Section5Bg,
  Section5Mockup,
} from "../../images/SingleCase/WebRTC"

const dataForWebRTC = {
  type: "project",
  smart: true,
  classComponent: "WebRTC",
  nextLink: "cases/video-conference/",
  prevLink: "cases/city-portal/",
  data: {
    title: "Remote assistance",
    subtitle:
      "The mobile application is built on React Native and works on both iOS and Android. The purpose of App is to facilitate communication between technicians and users, and with the help of such an application it will be possible to carry out repairs remotely.",
    overview: {
      title: "Project overview",
      about: {
        title: "About client",
        content: "US based development studio.",
      },
      challenge: {
        title: "Business challenge",
        content:
          "End client is an enterprise hosting large number of service technicians. These technicians would often drive to households to conduct repairs and maintenance while often the repairs are trivial and can be performed by owner. The goal was to simplify the way company employees conduct maintenance for scattered households.",
      },
      specific: [
        {
          type: "Platform",
          content: ["Web", "iOS", "Android"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: [
            "React Native",
            "Node JS",
            "Twilio WebRTC",
            "AWS (Cognito, EC2, SES)",
            "MongoDB",
          ],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Project Manager",
              count: 1,
            },
            {
              type: "Developers",
              count: 3,
            },
            {
              type: "QA Engineer",
              count: 1,
            },
            {
              type: "Designer",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "5 months",
              count: "",
            },
          ],
        },
        {
          type: "Client",
          content: ["Enterprise"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "We developed Mobile and Web applications to be used by service technicians and household owners that allow video and audio calls via WebRTC, screen capture and sharing as well as basic user management. The solution allows classic and SAML login for technician as well as supports conference calls. Household owner-facing Web app supports mobile, tablets and desktop devices with the latest browser versions.",
      ],
    },
    delivered: {
      title: "Value delivered",
      list: [
        "Tool adopted for internal company use as well as by franchisees across the country.",
      ],
    },
    images: {
      bgTop: Section1Bg,
      bgImage3: Section3Bg,
      mockup3: Section3Mockup,
      bgImage5: Section5Bg,
      mockup5: Section5Mockup,
    },
  },
  imageSrc: [],
  relatedProjects: [
    {
      link: "/cases/2cents",
      id: "enjoy",
      title: "2cents",
    },
    {
      link: "/cases/crm-sap-integration",
      id: "crmSap",
      title: "CRM/SAP Integration",
    },
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
  ],
  link: "/cases/webrtc",
}

export default dataForWebRTC
