import React from "react"

const renderStatictic = props => {
  if (
    props.overview.statistic &&
    Array.isArray(props.overview.statistic) &&
    props.overview.statistic.length > 0
  ) {
    return (
      <div className="row">
        <div className="col-md-11 overview--statistic">
          {props.overview.statistic.map(item => (
            <div className="overview--statistic__item" key={item.type}>
              <span className="circle__icon">
                <i className={`fa ${item.icon}`} aria-hidden="true"></i>
              </span>
              <span>
                {item.type}
                <div>{new Intl.NumberFormat("de-DE").format(item.number)}</div>
              </span>
            </div>
          ))}
        </div>
      </div>
    )
  }
  return null
}

const OverviewComponent = props => {

  const content = props.overview.challenge.content;

  return (
    <section className={`overview ${props.classComponent}`} style={props.style} id='Shoki-overview'>
      <div className="container">
        <h2 className={props.classComponent}>{props.overview.title}</h2>
        <div className="row" id="Shoki-row">
          <div className="col-md-7 ">
            <h3>{props.overview.challenge.title}</h3>
            {
              typeof content === 'object' && content !== null
              ? <div className="project-overview">
                  <div className="project-overview-text">{content.textOne}</div>
                  <div className="project-overview-text">{content.textTwo}</div>
                  <ul className="project-overview-list">
                    {
                      content.list.map(item =>
                        <li key={item.id}>{item.text}</li>
                      )
                    }
                  </ul>
                </div>
              : <p>{content}</p>
            }
          </div>
          <div className="col-md-3 col-md-offset-2">
            <ul>
              {props.overview.specific.map(item => (
                <li key={item.type}>
                  <i className={`fa ${item.icon}`} aria-hidden="true"></i>
                  <h4>{item.type}</h4>
                  <span>
                    {item.content.map((elem, i) => {
                      if (typeof elem === "string") {
                        const separator =
                          i + 1 < item.content.length ? ", " : ""
                        return `${elem}${separator}`
                      } else if (elem instanceof Object) {
                        const content =
                          elem.count !== 0 || elem.count !== ""
                            ? `${elem.count} ${elem.type}`
                            : elem.type
                        return <div key={elem.type}>{content}</div>
                      }
                    })}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {renderStatictic(props)}
      </div>
    </section>
  )
}
export default OverviewComponent
