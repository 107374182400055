import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Slider from "react-slick"

class RelatedProjects extends React.Component {
  constructor(props) {
    super(props)
    this.handleResize = this.handleResize.bind(this)
    this.state = {
      screenWidth: null,
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize() {
    if (window && window.innerWidth) {
      this.setState({
        screenWidth: window.innerWidth,
      })
    }
  }

  renderRelatedList() {
    const { relatedProjects } = this.props
    return relatedProjects.map((data, i) => (
      <div key={i} className="related-card">
        <a href={data.link} className="full-link-wrap">
          <div
            className="bottom-portfolio tab-wrapper small-portfolio"
            id={data.id}
          >
            <h3>{data.title}</h3>
          </div>
        </a>
      </div>
    ))
  }

  renserSlider() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    return <Slider {...settings}>{this.renderRelatedList()}</Slider>
  }

  renderRelatedProjects() {
    const { screenWidth } = this.state
    if (screenWidth && screenWidth < 768) {
      return this.renserSlider()
    }
    return this.renderRelatedList()
  }

  render() {
    return (
      <section className="related-section">
        <div className="">
          <h4>More projects</h4>
          <div className="tab-content">{this.renderRelatedProjects()}</div>
        </div>
      </section>
    )
  }
}
export default RelatedProjects

RelatedProjects.propTypes = {
  relatedProjects: PropTypes.array,
}

RelatedProjects.defaultProps = {
  relatedProjects: [],
}
