import React from "react"

import { Link } from "gatsby"

import Arrow1 from "../../../images/SingleCase/Navigation/Arrow1"
import Arrow2 from "../../../images/SingleCase/Navigation/Arrow2"

class Navigate extends React.Component {
  render() {
    const { prevLink, nextLink, classComponent } = this.props
    return (
      <section className={`navigate ${classComponent}`}>
        <div className="container">
          <div className="navigate__block">
            <Link to={`/${prevLink}`}>
              <Arrow2 />
              <span>Prev</span>
            </Link>
            <Link to="/cases/">
              <div className="squareBlock">
                <div />
                <div />
                <div />
                <div />
              </div>
            </Link>
            <Link to={`/${nextLink}`}>
              <span>Next</span>
              <Arrow1 />
            </Link>
          </div>
        </div>
      </section>
    )
  }
}
export default Navigate
