import React from "react"

export default function Arrow2() {
  return (
    <svg
      width="19"
      height="31"
      viewBox="0 0 19 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.92496 15.293L18.7622 3.45576L15.5994 0.292969L0.599367 15.293L15.5994 30.293L18.7622 27.1302L6.92496 15.293Z"
        fill="#375AD7"
      />
    </svg>
  )
}
