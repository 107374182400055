import React from "react"

export default function Arrow1() {
  return (
    <svg
      width="19"
      height="31"
      viewBox="0 0 19 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4378 15.293L0.600586 3.45576L3.76338 0.292969L18.7634 15.293L3.76338 30.293L0.600586 27.1302L12.4378 15.293Z"
        fill="#375AD7"
      />
    </svg>
  )
}
