import React from 'react';
import { Link } from 'gatsby';

import './ContactBtnBlock.scss';

const ContactBtnBlock = () => {
  return (
    <div className='contact-btn-container'>
      <div className='contact-btn-wrapper'>
        <Link to='/contact/'
          className='contact-btn-button'
        >
          <span className='contact-btn-text'>Let's work together</span>
        </Link>
      </div>
    </div>
  )
}

export default ContactBtnBlock;
