import React from "react"

import OverviewComponent from "../OverviewComponent"

export default function WebRTC({ data }) {
  let i = 0
  return [
    <section key={i++}>
      <div
        className="bgBlock bgImage webrtc"
        style={{ backgroundImage: `url(${data.images.bgTop})` }}
      >
        <div className="container">
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="col-md-8" style={{ height: "100%" }}>
              <div
                className="titleBlock"
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <h1>{data.title}</h1>
                <span id="webrtc-subtitle">{data.subtitle}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>,

    <OverviewComponent {...data} classComponent="webrtc paddings" key={i++} />,

    <section className="solution webrtc" key={i++}>
      <div
        className="solutionImgBg"
        style={{ backgroundImage: `url(${data.images.bgImage3})` }}
      >
        <img src={data.images.mockup3} alt="webrtc picture" />
      </div>
    </section>,

    <section className="white-bg solution paddings webrtc" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2>{data.solution.title}</h2>
            {data.solution.textFirst.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </section>,

    <section className="solution webrtc last" key={i++}>
      <div
        className="solutionImgBg"
        style={{ backgroundImage: `url(${data.images.bgImage5})` }}
      >
        <img src={data.images.mockup5} alt="webrtc picture" />
      </div>
    </section>,

    <section className="white-bg solution paddings last" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2>{data.delivered.title}</h2>
            {data.delivered.list.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </section>,
  ]
}
